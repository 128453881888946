exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PasswordStrengthIndicator_cnt{text-align:right;margin-top:.4em;line-height:1.5em}.PasswordStrengthIndicator_status{font-size:var(--normalText_SmallerFontSize,12px);margin-right:.25em}.PasswordStrengthIndicator_indicator{background:var(--inputs_BackgroundColor,#f2f2f2);height:3px;position:relative;margin-bottom:.1em}.PasswordStrengthIndicator_indicator:after,.PasswordStrengthIndicator_indicator:before{background:var(--validation_Error_FontColor,#cb2245);content:\"\";width:0;height:3px;position:absolute;top:0;left:0;transition:all .3s linear}.PasswordStrengthIndicator_indicator:after{background:var(--validation_Success_FontColor,#13a600);opacity:0}.PasswordStrengthIndicator_indicator[data-score=\"1\"]:before{width:25%}.PasswordStrengthIndicator_indicator[data-score=\"2\"]:before{width:50%;background:var(--validation_Warning_FontColor,#f78400)}.PasswordStrengthIndicator_indicator[data-score=\"2\"]:after{width:50%}.PasswordStrengthIndicator_indicator[data-score=\"3\"]:before{width:75%;background:var(--validation_Warning_FontColor,#f78400);opacity:.5}.PasswordStrengthIndicator_indicator[data-score=\"3\"]:after{width:75%;opacity:.6}.PasswordStrengthIndicator_indicator[data-score=\"4\"]:before{width:100%;background:var(--validation_Success_FontColor,#13a600);opacity:0}.PasswordStrengthIndicator_indicator[data-score=\"4\"]:after{width:100%;opacity:1}", ""]);

// exports
exports.locals = {
	"cnt": "PasswordStrengthIndicator_cnt",
	"status": "PasswordStrengthIndicator_status",
	"indicator": "PasswordStrengthIndicator_indicator"
};
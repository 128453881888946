import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { CheckboxCustom } from 'components/primitives/form';

const CheckboxFieldCustom = props => (
  <Field
    type="checkbox"
    initialValue={false}
    as={CheckboxCustom}
    {...props}
  />
);

CheckboxFieldCustom.propTypes = fieldPropTypes;

export default CheckboxFieldCustom;
